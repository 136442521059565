import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/Default"
import Breadcrumb from "../../components/common/Breadcrumb"
import Container from "../../components/layouts/container/Container"
import SectionTitle from "../../components/common/SectionTitle"
import Cta from "../../components/common/Cta"
import HeaderImage from "../../components/common/HeaderImage"
import ColumnsThree from "../../components/layouts/container/ColumnsThree"
import ServiceSidebar from "../../components/common/ServiceSidebar"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  const svg = {
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
  }
  return (
    <Layout
      location={location}
      title="Anfertigung Grundriss | Döbbel Immobilien"
      name="Leistungen"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb
        links={[
          { link: "Leistungen", to: "/leistungen/" },
          { link: "Anfertigung Grundriss", to: "/leistungen/grundriss/" },
        ]}
      />
      <Container className="my-8 md:my-16">
        <ColumnsThree>
          <div className="md:col-span-2">
            <SectionTitle
              title="Anfertigung Grundriss"
              subtitle="Leistungen"
              text="Einfach schöne und günstige Grundrisse und Visualisierungen Ihrer Immobilie."
              className="mb-6"
            />
            <P className="mb-3">
              Die beste Wohnungsanzeige ist wertlos ohne ausdrucksstarke Fotos
              und einen Grundriss, der auf den ersten Blick eine Übersicht
              vermittelt. Dadurch wissen Interessenten bereits, wie die Wohnung
              aufgeteilt ist und können damit beginnen, sie vor dem geistigen
              Auge einzurichten. Auch individuelle Vorlieben wie Abstellraum,
              Wohnküche oder eine getrennte Toilette lassen sich so auf den
              ersten Blick erkennen.
            </P>
            <P className="mb-3">
              Die Aufteilung spielt vor allem dann eine Rolle, wenn man nicht
              allein in die Wohnung zieht. Lange Flure, durch die Küche und
              Schlafzimmer einige Meter voneinander entfernt liegen, werden von
              Paaren bevorzugt, die zu unterschiedlichen Tageszeiten aktiv sind.
              So wird ein Schlafender nicht vom Partner gestört, der sich
              morgens Frühstück zubereitet. Familien benötigen ein ruhig
              gelegenes Kinderzimmer, Gourmets hingegen legen Wert auf eine
              große Küche. Mehrpersonenhaushalte wünschen sich häufig zwei
              Badezimmer, von denen mindestens eines direkt am Schlafzimmer
              liegt.
            </P>
          </div>
          <div>
            <ServiceSidebar name="Anfertigung Grundriss" />
          </div>
        </ColumnsThree>
      </Container>

      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "grundriss.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
